import { Injector, Pipe, PipeTransform } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';

@Pipe({
    name: 'elapsedTime'
})
export class ElapsedTimePipe extends AppComponentBase implements PipeTransform {

    constructor(injector: Injector) {
        super(injector);
    }

    transform(minutes: number): string {
        if (minutes < 60) {
          return `${minutes} minutes`;
        } else if (minutes < 1440) {
          const hours = (minutes / 60).toFixed(1);
          return `${hours} hours`;
        } else {
          const days = (minutes / 1440).toFixed(1);
          return `${days} days`;
        }
      }
}
