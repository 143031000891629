import { Injector, Pipe, PipeTransform } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';

@Pipe({
    name: 'timeAgo'
})
export class TimeAgoPipe extends AppComponentBase implements PipeTransform {

    constructor(injector: Injector) {
        super(injector);
    }

    transform(value: Date): string {
      const now = new Date();
      const userOffset = now.getTimezoneOffset() * 60 * 1000; // convert minutes to milliseconds
      const diff = now.getTime() - (value.getTime() - userOffset);
      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);

      if (seconds < 60) {
        return `${seconds} seconds ago`;
      } else if (minutes < 60) {
        const remainingSeconds = seconds % 60;
        return `${minutes} minutes, ${remainingSeconds} seconds ago`;
      } else if (hours < 24) {
        const remainingMinutes = minutes % 60;
        return `${hours} hours, ${remainingMinutes} minutes ago`;
      } else {
        return value.toDateString();
      }
    }
}
