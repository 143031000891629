import { Component, Input, OnInit } from '@angular/core';
import Litepicker from 'litepicker';

@Component({
  selector: 'app-litepicker',
  template: '<div class="litepicker"></div>',
})
export class LitepickerComponent implements OnInit {
  @Input() options: any; //Litepicker.Options;
  @Input() id: string;

  private litepicker: Litepicker;

  ngOnInit() {
    this.litepicker = new Litepicker({
      element: document.getElementById(this.id),
      ...this.options,
    });
  }
}